import SmoothScroll from 'smooth-scroll';

import '../styles/app.scss';

(() => {
    const scroll = new SmoothScroll('a[href*="#"]', {
        header: '.section-header',
        updateURL: false,
    });

    if (window.location.hash) {
        window.scroll(0,0);
        scroll.animateScroll(document.querySelector(window.location.hash))
    }
})();

(() => {
    const header = document.querySelector('.section-header');
    let previousTop = 0;

    window.addEventListener('scroll', () => {
        const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        const diff = previousTop > top ? previousTop - top : top - previousTop;

        if (top <= 80 || (window.innerWidth < 768 && diff > 10 && previousTop > top)) {
            header.classList.remove('section-header--small');
        } else if (window.innerWidth > 767 || (diff > 10 && previousTop < top)) {
            header.classList.add('section-header--small');
        }

        previousTop = diff > 10 ? top : previousTop;
    });
})();

(() => {
    const searchField = document.querySelector('.section-header__search-field');
    searchField.addEventListener('focus', (e) => {
        e.target.classList.add('section-header__search-field--focus');
    });
    searchField.addEventListener('blur', (e) => {
        setTimeout(() => {
            e.target.classList.remove('section-header__search-field--focus');
        }, 200);
    });
})();

(() => {
    document.querySelector('.section-header__navtoggle').addEventListener('click', (e) => {
        e.preventDefault();
        document.body.classList.toggle('layout--navigation');
    });

    document.querySelectorAll('.section-header__navigation li').forEach((el) => {
        el.querySelector('a, strong').addEventListener('click', (e) => {
            if (window.innerWidth >= 1265
                || (window.innerWidth - e.clientX > 60 && (!el.parentNode.classList.contains('level_2') || !el.classList.contains('submenu')))
            ) {
                return;
            }

            e.preventDefault();

            if (el.classList.contains('open')) {
                el.classList.remove('open');
            } else {
                for (let i = 0; i < el.parentNode.children.length; i++) {
                    el.parentNode.children[i].classList.remove('open');
                }

                el.classList.add('open');
            }
        });
    });
})();

(() => {
    const infonav = document.querySelectorAll('.section-info__navigation .level_1 > li');

    if (!infonav[0]) {
        return;
    }

    let trail = infonav[0].parentNode.querySelector('.trail');

    if (!trail) {
        trail = infonav[0];
    }

    trail.classList.add('open');

    infonav.forEach((el) => {
        const label = el.querySelector('a, strong');

        label.addEventListener('click', (e) => {
            if (window.innerWidth >= 768 || window.innerWidth - e.clientX > 60) {
                return;
            }

            e.preventDefault();

            if (el.classList.contains('open')) {
                infonav.forEach((li) => {
                    li.classList.remove('open');
                });
            } else {
                el.classList.add('open');
            }
        });

        label.addEventListener('mouseenter', () => {
            if (window.innerWidth < 768) {
                return;
            }

            infonav.forEach((li) => {
                li.classList.remove('open');
            });

            el.classList.add('open');
        });
    });

    const updateHeight = () => {
        if (window.innerWidth < 768) {
            document.querySelector('.section-info__navigation').setAttribute('style', '');
            return;
        }

        let maxHeight = 0;

        document.querySelectorAll('.section-info__navigation .level_2').forEach((el) => {
            if (el.offsetHeight > maxHeight) {
                maxHeight = el.offsetHeight;
            }
        });

        document.querySelector('.section-info__navigation').setAttribute('style', `margin-bottom: ${maxHeight + 30}px`);
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
})();
